<template>
<div class="container-detalle">
  <div class="">
    <div class="row mb-3 justify-content-center">
      <div class="col-12">
        <h4 class="nominados-title">Nominados:</h4>
      </div>
      <div class="col-6 col-md-4 mb-4" v-for="(n, ix) in nominados" :key="ix">
        <div>
          <router-link :to="{name: 'perfil', params: {'id': n.id}}">
            <img width="100%" :src="`${publicPath}assets/images/nominados/${n.id}.png`" />
            <div class="nominado-name">
              {{ n.name }}
            </div>
          </router-link>
        </div>
      </div>

    </div>

  </div>
  
</div>

</template>

<style lang="scss">

  .nominados-title {
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: "Roboto";
  }

  .nominado-name {
    font-size: 14px;
    text-transform: uppercase;
    font-family: "AkiraExpandedSuperBold";
    border-bottom: solid 1px #bd9767ff;
    text-align: center;

    &:hover {
      color: #bd9767ff;
    }
  }

</style>

<script>



export default {
  name: 'NominadosList',
  props: {
    nominados: {
      type: Array
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
