<template>
  <div class="wrapper-nominados">
    <b-tabs class="submenu-nominados">
      <b-tab active>
        <template #title>
          MI UNIVISIONARIO
        </template>
        <p class="p-3"><NominadosList/></p>
      </b-tab>
  
      <b-tab>
        <template #title>
          PIONEROS
        </template>
        <p class="p-3"><NominadosList/></p>
      </b-tab>
      <b-tab>
        <template #title>
          EMPRENDEDORES
        </template>
        <p class="p-3"><NominadosList/></p>
      </b-tab>
      <b-tab>
        <template #title>
          INNOVADORES
        </template>
        <p class="p-3"><NominadosList/></p>
      </b-tab>
      <b-tab>
        <template #title>
          MAESTROS
        </template>
        <p class="p-3"><NominadosList/></p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">

  .nominados-header {
    display: block;
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
  }

  .nominados-main-title {
    font-family: "AkiraExpandedSuperBold";
    margin-top: 65px;
  }

  .excerpt {
    max-width: 720px;
    margin: 25px auto;
    text-align: left;
  }

  .nav-tabs {
    border: none !important;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .submenu-nominados {
    margin: 0;
    padding: 0;
    border: none;

    li {
      display: inline-block;
      margin: 0 15px;
      font-family: "TuskerGroteskBold";
      letter-spacing: 1px;
      text-transform: uppercase;
      background: transparent;

      .nav-link {
        border: none;
      }

      .nav-link.active {
        border: none;
        color: #fff;
        background: transparent;
      }

      a {
        font-size: 27px;
        color: var(--app-yellow);

        &.router-link-active {
          border-bottom: solid 2px #ae9269;
        }
        
        &:hover {
          border-bottom: solid 2px #ae9269;
        }
      }
    }
  }

</style>

<script>

import NominadosList from '@/components/NominadosList'

export default {
  name: 'Nominados',
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  components: {
    NominadosList
  },
  methods: {
    countLink(link, name) {
      window.dataLayer.push({
        event: "categorias_click",
        event_action: `categoriasnav-${link}`
      })

      if(document.getElementById(name)) {
        const top = document.getElementById(name).offsetTop
        document.getElementById(name).classList.add('active')
        window.scrollTo(0, top)
      }
    },
  },
  computed: {
    article() {
      return require(`@/i18n/es.json`)
    }
  }
}
</script>
